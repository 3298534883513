














































































































































































































































































































 .v-distpicker ::v-deep select {
  height: 32px !important;
  padding-top: 0.4rem;
  font-size: 14px;
}
    .el-information{
        .el-col{
            margin-bottom:15px ;
        }
    }
    .abow_dialog{
        font-weight: bold;
     
    
        p{
    float: left;
        }
    }
    
    .el-icon-s-fold,.el-icon-s-unfold{
        font-size: 35px;
        margin:22px ;
        color: #999;
    }
    .mr15 {
     
      margin-right: 15px;
    }
    